export default [{
		path:'admin/ug_list',
		component(){
			return import('@/pages/admin_user/ug/list.vue')
		}
	},{
		path:'admin/user_list',
		component(){
			return import('@/pages/admin_user/user/list.vue')
		}
	},
]
//app用户 的页面路由
export default [{
		path:'tord/tord_auth_list',
		component(){
			return import('@/pages/tord/tord_auth/list.vue')
		}
	},{
		path:'tord/tord_batch_list',
		component(){
			return import('@/pages/tord/tord_batch/list.vue')
		}
	},{
		path:'tord/tord_list',
		component(){
			return import('@/pages/tord/tord_list/list.vue')
		}
	}
]
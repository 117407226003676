import Vue from 'vue'
import store from './store'
Vue.prototype.$store = store;

//加载工具包
import my from './my'
my.$store=store
Vue.prototype.$my = my;

//阻止生产模式的消息
Vue.config.productionTip = false

//element-ui
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
Vue.use(ElementUI)

//element-ui patche
import patche from './patche'
import myButton from "@/components/Elcustom/myButton.vue";
Vue.component("auth-button", myButton);

//高德地图相关
import amap from '@amap/amap-jsapi-loader';
window._AMapSecurityConfig = {
	securityJsCode:'7d2b33d26bdf19be8516d276c1ec6c91',
}
amap.load({

	// 申请好的Web端开发者Key，首次调用 load 时必填
	key: "7feeff8d1c2db436b775c76eb1fe2eed",

	// 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
	version: "2.0",

	// 需要使用的的插件列表，如比例尺'AMap.Scale'等
	plugins: [],
})
Vue.prototype.$amap = amap;


//路由器配置
import router from 'vue-router'
Vue.use(router);                            //挂载路由
import router_config from './router'        //加载路由全局配置
const new_router=new router(router_config); //创建路由
new_router.beforeEach((to,from,next)=>{     //创建路由守卫
    my.routerBeforeEach(to,from,next)
})

//启动项目
const app = new Vue({
    router:new_router,
    render: h => h('router-view')
}).$mount('#app')


//骚操作
Vue.prototype.$my.install(app)
//app用户 的页面路由
export default [{
		path:'logistics/company_list',
		component(){
			return import('@/pages/logistics/company/list.vue')
		}
	},{
		path:'logistics/park_list',
		component(){
			return import('@/pages/logistics/park/list.vue')
		}
	},{
		path:'logistics/node_list',
		component(){
			return import('@/pages/logistics/node/list.vue')
		}
	},{
		path:'logistics/route_list',
		component(){
			return import('@/pages/logistics/route/list.vue')
		}
	},
]
//app用户 的页面路由
export default [{
		path:'customer/user_list',
		component(){
			return import('@/pages/customer/user/list.vue')
		}
	},{
		path:'customer/agent_list',
		component(){
			return import('@/pages/customer/agent/list.vue')
		}
	},{
		path:'customer/agent_bind',
		component(){
			return import('@/pages/customer/agent_bind/list.vue')
		}
	},
]
export default{
	routes:[{
			path:'*',
			component(){
				return import('@/pages/public/menu.vue')
			}
		},{
			name:'login',
			path:'/login',
			component(){
				return import('@/pages/public/login.vue')
			}
		},{
			name:'index',
			path:'/pages',
			component(){
				return import('@/pages/public/menu.vue')
			},
			children:[
				
				//dev部分
				...require('./extend/dev.js').default,

				//admin部分
				...require('./extend/admin_user.js').default,

				//客户数据部分
				...require('./extend/customer.js').default,

				//库存部分
				...require('./extend/logistics.js').default,

				//库存部分
				...require('./extend/sku.js').default,

				//库存部分
				...require('./extend/tord.js').default,
			]
		},
	],
};
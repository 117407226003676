export default {
	
	//构造函数
	make_upl_obj(obj){
		
		//初始化
		this.init=function(obj){
			
			//用户数据
			this.other_para=obj.other_para
			
			//初始化config
			this.config={};
			
			if(!obj.bucket){
				this.__parent.other.msg({
					type:'warning',
					str:"缺少'bucket'"
				});
				return;
			}else this.config.bucket=obj.bucket
			
			if(!obj.file_path){
				this.__parent.other.msg({
					type:'warning',
					str:"缺少'file_path'"
				});
				return;
			}else this.config.file_path=obj.file_path
			
			//回调
			this.config.callback=obj.callback
			
			//key
			this.config.key=this.get_key_version(obj.key)
			
			//token
			if(obj.token){//自带token
				
				//置入
				this.config.token=obj.token
				
				//自带token 发起上传
				this.do_upl();
				
			}else{//没有token
				
				//获取token
				this.get_token();
			}
		}
		
		//获取token
		this.get_token=function(){
			
			//调接口
			this.__parent.net.req({
				data:{
					mod:'res',
					ctr:'get_qiniu_upl_token_api',
					bucket:this.config.bucket,
					key:this.config.key.upl_key,
				},
				callback:(data)=>{
					
					this.config.key.upl_key=data.key
					
					this.config.region=data.region
					
					this.config.token=data.token
					
					this.config.upl_url=data.url
					
					this.do_upl();
				}
			});
		}
		
		this.do_upl=function(){
			
			let form=new FormData();// FormData 对象
			form.append("file",this.files_cache[this.config.file_path]); // 文件对象
			form.append("token",this.config.token); // 文件对象
			form.append("key",this.config.key.upl_key); // 文件对象
			
			let xhr = new XMLHttpRequest();  // XMLHttpRequest 对象
			xhr.open("post",this.config.upl_url,true); //post方式，url为服务器请求地址，true 该参数规定请求是否异步处理。
			xhr.onload = (res)=>{//请求完成
				if(res.target.status!=200){
					this.__parent.other.msg({
						type:'warning',
						str:'上传失败'
					});
					return;
				}
				
				//删除files_cache缓存
				delete(this.files_cache[this.config.file_path])
				
				//生成携带version的key 并执行回调
				this.config.callback(this.make_new_key(JSON.parse(res.target.responseText)),this.other_para)
			}; 
			//xhr.onerror =  uploadFailed; //请求失败

			//xhr.upload.onprogress = progressFunction;//【上传进度调用方法实现】
			// xhr.upload.onloadstart = function(){//上传开始执行方法
			// 	ot = new Date().getTime();   //设置上传开始时间
			// 	oloaded = 0;//设置上传开始时，以上传的文件大小为0
			// };

			xhr.send(form); //开始上传，发送form数据
		}
		
		this.make_new_key=function(upld_data){
			
			return {
				key:upld_data.key+"__v__"+this.config.key.version,
				upl_key:upld_data.key,
				varsion:this.config.key.version,
				hash:upld_data.key
			}
		}
		
		this.get_key_version=function(key){
			
			if(!key){
				return {//如果没有传入key 则返回 上传key=false 和 version=1
					upl_key:false,
					version:1
				}
			}
			
			//分离 key 和 version
			let key_arr=key.split('__v__')
			let old_version=key_arr[1];
			
			//生成新的version
			let new_version
			if(old_version){
				new_version=(old_version*1+1).toFixed(0)
			}else new_version=1
			
			//返回结果
			return {
				upl_key:key_arr[0],
				version:new_version
			}
		}
	},
	
	//文件对象缓存
	files_cache:{},
	
	//上传
	upl(obj){
		
		let upl_obj=new this.make_upl_obj();
		
		upl_obj.files_cache=this.files_cache
		
		upl_obj.__parent=this.__parent
		
		upl_obj.init(obj);
	},
	
	//生成图片地址
	make_src(bucket,key){

		if(!key){
			return;
		}
		
		//取出 buckets
		let buckets=this.__parent.$store.state.bucket
		let this_bucket=buckets[bucket]
		
		//分离 upl_key 和 version
		let key_arr=key.split('__v__')
		let upl_key=key_arr[0];
		let version=key_arr[1];
		if(!upl_key){
			this.__parent.other.msg({
				type:'warning',
				str:"qiniu插件(qiniu.js):make_src函数(第159行)出错,请前往查看"
			});
			return;
		}
		
		//拼接并返回
		return this_bucket+upl_key+"?__version__="+version
	},
	choose_img(options){
		
		let size=options.size?options.size:'4096000'//bit
		
		let accept=options.accept?options.accept:'image/*'
		
		//input对象实例
		let input=document.createElement('input')
		input.type='file'
		input.accept=accept
		input.addEventListener('change',()=>{
			if(input.files[0].size>size){
				this.__parent.other.msg({
					type:'warning',
					str:'文件过大'
				});
			}
			
			let res={
				name:input.files[0].name,
				size:input.files[0].size,
				type:input.files[0].type,
			};
			if(window.createObjectURL!=undefined){ // basic
				res.src = window.createObjectURL(input.files[0]);
			}else if(window.URL!=undefined){ // mozilla(firefox)
				res.src=window.URL.createObjectURL(input.files[0]) ;
			}else if(window.webkitURL!=undefined){ // webkit or chrome
				res.src=window.webkitURL.createObjectURL(input.files[0]) ;
			}
			
			//缓存file对象
			this.files_cache[res.src]=input.files[0]
			
			//回调
			options.success(res)
		})
		input.click()
	},
}
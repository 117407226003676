//app用户 的页面路由
export default [{
		path:'sku/sku_of_zhengdian_list',
		component(){
			return import('@/pages/sku/zhengdian/list.vue')
		}
	},{
		path:'sku/sku_of_mengtian_hengkai_list',
		component(){
			return import('@/pages/sku/mengtian_hengkai/list.vue')
		}
	},{
		path:'sku/sku_of_mengtian_qingyuan_list',
		component(){
			return import('@/pages/sku/mengtian_qingyuan/list.vue')
		}
	},
]
export default [{
		path:'dev/menu_list',
		component(){
			return import('@/pages/dev/menu/list.vue')
		}
	},{
		path:'dev/api_test',
		component(){
			return import('@/pages/dev/api_test.vue')
		}
	},{
		path:'dev/db_comparison',
		component(){
			return import('@/pages/dev/db_comparison.vue')
		}
	},
]
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {

		//初始化
		init_status:1,//初始化状态(1:未初始化,2:初始化中,3:已初始化成功,4:初始化失败)
		agent_para:{},//代理信息
		bucket:{},//七牛bucket
		logink_aim_list:{},//上报目标列表

		//用户相关
		is_login:2,//是否已登录(1:已登录,2:未登录)
		user_info:{},//用户信息
		url_btns:{},//页面地址对应页内权限按钮

		//当前页面地址
		now_url:'',
	},
	mutations: {
		end_of_init(state,data){//初始化之后
			if(data.init_status){
				state.init_status=data.init_status
			}
			if(data.agent_para){
				state.agent_para=data.agent_para
			}
			if(data.bucket){
				state.bucket=data.bucket
			}
			if(data.logink_aim_list){
				state.logink_aim_list=data.logink_aim_list
			}
		},
		end_of_login(state,data){//登陆成功之后
			if(data.is_login){
				state.is_login=data.is_login
			}
			if(data.user_info){
				state.user_info=data.user_info
			}

			//如果已登录则处理菜单
			if(data.is_login==1){

				//菜单处理
				let menu_list=data.user_info.menu_list
				let url_btns={};
				for(var item of menu_list){

					//页面地址对应页内权限按钮
					let item_url=item.url.trim()
					if(item_url){
						let item_controlled_btns=item.controlled_btns.split(',')
						for(var btn_index in item_controlled_btns){
							if(!item_controlled_btns[btn_index].trim()){
								item_controlled_btns.splice(btn_index,1)
							}
						}
						url_btns[item_url]=item_controlled_btns
					}
				}

				state.url_btns=url_btns
			}
		},
		end_of_logout(state){//登出之后
			state.is_login=2
			state.user_info={}
		},
		save_user_info(state,data){//保存用户数据
			state.user_info=data
		},
		save_now_url(state,data){//保存当前页面地址
			state.now_url=data
		},
	},
	actions: {
		
	}
})

export default store
